import { useState, useEffect } from "react";
import gsap from "gsap";
import { Bounce, Power4, Power3 } from "gsap/all";
import styled from "styled-components";
import {
  Menu,
  Item,
  Separator,
  Submenu,
  useContextMenu,
  theme,
  animation,
} from "react-contexify";
import GlobalStyles from "../src/style/globalStyle";
import MobileStyles from "../src/style/mobileStyles";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "../src/style/themes";
import { copywriteCn, copywriteEn } from "./copywrite";
import Dock from "../src/components/Dock";
import AppUI from "../src/components/AppUI";
// import Menu from "../src/components/Menu";
import wallpaperDay from "../src/assets/BigSurColorfulDay.jpg";
import wallpaperNight from "../src/assets/BigSurColorfulNight.jpg";
import macOS from "../src/assets/macOS.svg";
import windows from "../src/assets/windows.svg";
import ic_figma from "../src/assets/footer_figma.svg";
import ic_wechat from "../src/assets/footer_wechat.svg";
import ic_github from "../src/assets/footer_github.svg";
import ic_twitter from "../src/assets/footer_twitter.svg";
import ic_zhihu from "../src/assets/footer_zhihu.svg";
import wechat_qr from "../src/assets/wechat-qr.png";
import footerLogoLight from "../src/assets/fc-logo-light.png";
import footerLogoDark from "../src/assets/fc-logo-night.png";
import {
  ButtonDropArrow,
  IconSun,
  IconMoon,
  IconLove,
  IconLang,
  IconInfo,
  IconClose,
} from "./svg";
import "react-contexify/dist/ReactContexify.css";
import Lottie from "react-lottie";
import * as animationData from "../src/assets/lottie/data.json";

const MENU_ID = "menu-id";

function App() {
  const [macListShow, setMacListShow] = useState(false);
  const [isPausedState, setIsPausedState] = useState(true);
  const [isEn, setIsEn] = useState(false);
  const [copywrite, setCopywrite] = useState(copywriteCn);
  const [modalView, setModalView] = useState(false);
  const [alertModalView, setAlertModalView] = useState(false);
  const [theme, setTheme] = useState("light");
  const [openAlert, setOpenAlert] = useState(false);

  const themeToggler = () => {
    theme === "light" ? setTheme("dark") : setTheme("light");
  };

  useEffect(() => {
    let prefersDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    if (prefersDarkMode) {
      if (prefersDarkMode) {
        setTheme("dark");
      } else {
        setTheme("light");
      }
    }
    let media = window.matchMedia("(prefers-color-scheme: dark)");
    let callback = (e) => {
      let prefersDarkMode = e.matches;
      if (prefersDarkMode) {
        setTheme("dark");
      } else {
        setTheme("light");
      }
    };
    if (typeof media.addEventListener === "function") {
      media.addEventListener("change", callback);
    } else if (typeof media.addEventListener === "function") {
      media.addEventListener(callback);
    }
  }, []);

  useEffect(() => {
    const tl = gsap.timeline();

    window.addEventListener("resize", () => {
      gsap.set(".hero-left-app", {
        scale: () => {
          if (window.innerWidth > 1440) {
            return 1.2;
          } else if (window.innerWidth < 600) {
            return 0.45;
          } else {
            return 0.9;
          }
        },
      });
      gsap.set(".dock", {
        transformOrigin: "bottom center",
        scale: () => {
          if (window.innerWidth < 600) {
            return 0.5;
          } else {
            return 1;
          }
        },
      });
    });

    gsap.set(".hero-left-app", {
      scale: 0.6,
      opacity: 0,
      ease: "back.out(1.7)",
      perspective: 1200,
    });

    gsap.set(".dock", {
      y: 80,
      scale: 0.9,
      opacity: 0,
    });

    gsap.set(".hero-left-bg", {
      scale: 2,
      opacity: 0,
    });

    window.onload = function () {
      tl.to(
        ".hero-left-bg",
        {
          duration: 1.2,
          scale: 1,
          ease: Power4.out,
          opacity: 1,
        },
        "left"
      )
        .to(
          ".dock",
          {
            duration: 0.26,
            y: 0,
            transformOrigin: "bottom center",
            scale: () => {
              if (window.innerWidth < 600) {
                return 0.5;
              } else {
                return 1;
              }
            },
            opacity: 1,
            delay: 0.4,
            ease: Power3.out,
          },
          "left"
        )
        .from(
          ".figmabox-icon",
          {
            duration: 0.3,
            ease: Bounce.easeOut,
            y: -8,
            repeat: 2,
            delay: 0.6,
            onComplete: () => {
              if (window.innerWidth > 1100) {
                gsap.set(".hero-right", {
                  opacity: 1,
                });
              }
            },
          },
          "left"
        )
        .to(".hero-left-app", {
          perspective: 1200,
          scale: () => {
            if (window.innerWidth > 1440) {
              return 1.2;
            } else if (window.innerWidth < 600) {
              return 0.45;
            } else {
              return 0.9;
            }
          },
          opacity: 1,
          duration: 0.6,
          ease: "back.out(1.7)",
          rotateX: 0,
          y: 0,
        })
        .to(
          ".hero-left",
          {
            duration: 1.3,
            width: "60%",
            ease: Power3.easeOut,
            onComplete: () => {
              setIsPausedState(false);
            },
          },
          "move"
        )
        .to(".App", {
          paddingTop: 96,
        })
        .to(".alert", {
          y: 0,
        });
    };
  }, []);

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function handleItemClick({ event, props, triggerEvent, data }) {
    // console.log(event, props, triggerEvent, data);
    setAlertModalView(true);
  }

  function displayMenu(e) {
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    show(e);
  }

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (isEn) {
      setCopywrite(copywriteEn);
    } else {
      setCopywrite(copywriteCn);
    }
  }, [isEn]);

  function langHandle() {
    setIsEn(!isEn);
  }

  function alertInfo() {
    return {
      __html: copywrite.alert.content,
    };
  }

  function alertHandle() {
    setOpenAlert(true);
  }

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <AppCss
        className={`${openAlert ? "openAlert" : null} App`}
        onClick={() => {
          setMacListShow(false);
        }}
      >
        <div
          className={
            openAlert
              ? "info-modal info-modal-show fixed-alert-modal"
              : "info-modal fixed-alert-modal"
          }
        >
          <span>{copywrite.fixedAlert.title}</span>
          <p dangerouslySetInnerHTML={{ __html: copywrite.fixedAlert.des }}></p>
          <div onClick={() => setOpenAlert(false)} className="modal-close">
            <IconClose />
          </div>
        </div>
        <span className={`${openAlert ? "fixedAlertShow" : null} alert`}>
          <div>
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5 text-indigo-400"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            {copywrite.fixedAlert.title}
          </div>
          <button onClick={() => alertHandle()}>
            {copywrite.fixedAlert.button}
          </button>
        </span>
        <GlobalStyles />
        <MobileStyles />
        <div
          className={modalView ? "info-modal info-modal-show" : "info-modal"}
        >
          <span>{copywrite.info.title}</span>
          <p>{copywrite.info.content}</p>
          <div onClick={() => setModalView(false)} className="modal-close">
            <IconClose />
          </div>
        </div>
        <div
          className={
            alertModalView
              ? "info-modal-alert-show info-modal info-modal-alert"
              : "info-modal info-modal-alert"
          }
        >
          <span>{copywrite.alert.title}</span>
          <p dangerouslySetInnerHTML={alertInfo()}></p>
          <div onClick={() => setAlertModalView(false)} className="modal-close">
            <IconClose />
          </div>
        </div>
        <div>
          <Menu id={MENU_ID} animation={false}>
            <Submenu label={copywrite.hero.dropdown.mac}>
              <Item onClick={handleItemClick}>
                <a
                  href="https://cdn.coiven.com/download/Figma%20Box-1.0.0.dmg"
                  download
                >
                  {copywrite.hero.dropdown.intel}
                </a>
              </Item>
              <Item onClick={handleItemClick}>
                <a
                  href="https://cdn.coiven.com/download/Figma%20Box-1.0.0-arm64.dmg"
                  download
                >
                  {copywrite.hero.dropdown.m1}
                </a>
              </Item>
            </Submenu>
            <Item onClick={handleItemClick}>
              <a
                href="https://cdn.coiven.com/download/Figma%20Box%20Setup%201.0.0.exe"
                download
              >
                {copywrite.hero.dropdown.win}
              </a>
            </Item>
            <Separator />
            <Item>
              <a href="https://love.figma.cool" target="_blank">
                {copywrite.hero.dropdown.spon}
              </a>
            </Item>
            <Item>
              <a href="https://figma.cool" target="_blank">
                {copywrite.hero.dropdown.link}
              </a>
            </Item>
          </Menu>
        </div>
        <section className="hero-left" onContextMenu={show}>
          <Dock />
          <AppUI copywrite={copywrite} />
          <div className="hero-left-bg">
            <div
              className={
                theme === "dark"
                  ? "hero-left-bg-night hero-left-bg-night-show"
                  : "hero-left-bg-night"
              }
            ></div>
            <div className="hero-left-bg-day"></div>
          </div>
        </section>
        <section className="hero-right">
          <div className="hero-right-top">
            <a
              href="https://love.figma.cool"
              target="_blank"
              className="sponsor hero-right-top-button"
            >
              <span>{copywrite.tooltips.spon}</span>
              <IconLove />
            </a>
            <div
              className="hero-right-top-button lang-switch"
              onClick={langHandle}
            >
              <span>{copywrite.tooltips.lang}</span>
              <IconLang />
            </div>
            <div
              className="day-night hero-right-top-button"
              onClick={themeToggler}
            >
              <span>{copywrite.tooltips.dark}</span>
              <div className={theme === "light" ? "moon-show" : ""}>
                <IconMoon />
              </div>
              <div className={theme === "dark" ? "sun-show" : ""}>
                <IconSun />
              </div>
            </div>
          </div>
          <div className="hero-right-middle">
            <Lottie
              options={defaultOptions}
              height={260}
              width={260}
              isClickToPauseDisabled={true}
              isPaused={isPausedState}
            />
            <h1>{copywrite.hero.title}</h1>
            <p>{copywrite.hero.slogan}</p>
            <div className="button-group">
              <div
                className={
                  macListShow
                    ? "btn-mac btn-download btn-mac-active"
                    : "btn-mac btn-download"
                }
                onMouseEnter={() => setMacListShow(true)}
              >
                <img
                  src={macOS}
                  alt="macOS"
                  className={theme === "dark" ? "dark-filter" : ""}
                />
                {copywrite.hero.button.mac}
                <i className={theme === "dark" ? "dark-filter" : ""}>
                  <ButtonDropArrow />
                </i>
                <div
                  className={
                    macListShow
                      ? "mac-download-list  mac-download-list-show"
                      : "mac-download-list"
                  }
                  onMouseLeave={() => {
                    setMacListShow(false);
                  }}
                >
                  <a
                    href="https://cdn.coiven.com/download/Figma%20Box-1.0.0.dmg"
                    download
                    className="button-item"
                    onClick={() => setAlertModalView(true)}
                  >
                    {copywrite.hero.dropdown.intel}
                  </a>
                  <a
                    href="https://cdn.coiven.com/download/Figma%20Box-1.0.0-arm64.dmg"
                    download
                    className="button-item"
                    onClick={() => setAlertModalView(true)}
                  >
                    {copywrite.hero.dropdown.m1}
                  </a>
                </div>
              </div>
              <a
                href="https://cdn.coiven.com/download/Figma%20Box%20Setup%201.0.0.exe"
                download
                className="btn-win btn-download"
                onClick={() => setAlertModalView(true)}
              >
                <img src={windows} alt="win" />
                {copywrite.hero.button.win}
              </a>
            </div>
            <div className="app-info">
              <span className="version">
                <div className={theme === "dark" ? "dark-filter" : ""}>
                  <IconInfo />
                </div>
                {copywrite.info.version}
              </span>
              {/* <div
                className="mac-info alert-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setAlertModalView(true);
                }}
              >
                {copywrite.alert.title}
              </div> */}
              <div
                className="mac-info"
                onClick={(e) => {
                  e.stopPropagation();
                  setModalView(true);
                }}
              >
                {copywrite.info.title}
              </div>
              <a
                className="mac-info feedback-btn"
                target="_blank"
                href="https://wj.qq.com/s2/8769898/bd34"
              >
                {copywrite.feedback}
              </a>
            </div>
          </div>
          <div className="hero-right-bottom">
            <footer>
              <div className="footer-left">
                <a
                  className="footer-left-logo"
                  href="https://figma.cool"
                  target="_blank"
                >
                  <img
                    src={footerLogoLight}
                    alt="footerLogoLight"
                    className={
                      theme === "light"
                        ? "footer-left-logo-light"
                        : "footer-left-logo-light footer-left-logo-light-none"
                    }
                  />
                  <img
                    src={footerLogoDark}
                    alt="footerLogoDark"
                    className={
                      theme === "light"
                        ? "footer-left-logo-night"
                        : "footer-left-logo-night footer-left-logo-night-show"
                    }
                  />
                </a>
                <i></i>
                <p>{copywrite.footer}</p>
              </div>
              <div
                className={
                  theme === "dark" ? "dark-filter footer-right" : "footer-right"
                }
              >
                <a
                  target="_blank"
                  href="https://www.figma.com/@figmacool"
                  className="link-figma"
                >
                  <img src={ic_figma} alt="figma" />
                </a>
                <a
                  target="_blank"
                  href=""
                  className="link-wechat"
                  onClick={(e) => e.preventDefault()}
                >
                  <img src={ic_wechat} alt="wechat" />
                </a>
                <a
                  target="_blank"
                  href="https://github.com/Figma-Cool"
                  className="link-github"
                >
                  <img src={ic_github} alt="github" />
                </a>
                <a
                  target="_blank"
                  href="https://www.zhihu.com/people/figma-cool"
                  className="link-zhihu"
                >
                  <img src={ic_zhihu} alt="zhihu" />
                </a>
                <a
                  target="_blank"
                  href="https://twitter.com/FigmaCool"
                  className="link-twitter"
                >
                  <img src={ic_twitter} alt="twitter" />
                </a>
              </div>
            </footer>
          </div>
        </section>
      </AppCss>
    </ThemeProvider>
  );
}

const flexCenter = `
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppCss = styled.main`
  width: 100vw;
  height: 100vh;
  padding: 20px;
  display: flex;
  overflow: hidden;

  .dark-filter {
    filter: invert(1);

    .link-wechat:after {
      filter: invert(1) !important;
    }
  }

  .sun-show {
    opacity: 1 !important;
  }

  .moon-show {
    opacity: 1 !important;
  }

  .footer-left-logo-light-none {
    opacity: 0;
  }
  .footer-left-logo-night-show {
    opacity: 1 !important;
  }

  .hero-left-bg-night-show {
    z-index: 1;
    opacity: 1 !important;
    visibility: visible !important;
  }

  .info-modal-show {
    opacity: 1 !important;
    visibility: visible !important;
  }

  .info-modal-alert-show {
    opacity: 1 !important;
    visibility: visible !important;
  }

  .info-modal-alert {
    height: 300px !important;

    a {
      color: #1155cc;
    }
  }

  .alert-btn {
    margin-right: 12px;
  }

  .feedback-btn {
    color: ${({ theme }) => theme.text};
    margin-left: 12px;
  }

  .info-modal {
    opacity: 0;
    visibility: hidden;
    z-index: 99;
    position: fixed;
    padding: 60px 24px 24px 24px;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12),
      0px 11px 16px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    background: var(--cr-white);
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 448px;
    height: 180px;
    transition: var(--ease-1);

    .modal-close {
      cursor: pointer;
      position: absolute;
      top: 16px;
      right: 16px;
      width: 32px;
      height: 32px;
      border-radius: 6px;
      background: rgba(0, 0, 0, 0.04);
      transition: var(--ease-1);
      ${flexCenter}

      &:active {
        transform: scale(0.86);
      }

      &:hover {
        background: var(--cr-bg-6);
      }
    }

    span {
      position: absolute;
      top: 26px;
      left: 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #000000;
    }

    svg {
      width: 14px;
      height: 14px;
    }

    p {
      max-width: 400px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      opacity: 0.6;
    }
  }

  .hero-left {
    z-index: 10;
    width: 100vw;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
    transform: translateZ(0);
    ${flexCenter}

    .hero-left-bg {
      opacity: 0;
      width: 100%;
      height: 100%;
      ${flexCenter}

      div {
        position: absolute;
        transition: var(--ease-1);
      }

      &-day {
        width: 100%;
        height: 100%;
        background: url(${wallpaperDay}) no-repeat;
        background-size: cover;
      }
      &-night {
        opacity: 0;
        width: 100%;
        height: 100%;
        background: url(${wallpaperNight}) no-repeat;
        background-size: cover;
      }
    }
  }
  .hero-right {
    z-index: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    width: 40%;
    height: calc(100vh - 40px - 76px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 68px;
    transition: var(--ease-1);

    &-top {
      width: 100%;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      position: relative;
      right: -48px;

      &-button {
        cursor: pointer;
        position: relative;
        width: 32px;
        height: 32px;
        border-radius: 6px;
        background: ${({ theme }) => theme.iconBg};
        transition: var(--ease-1);
        ${flexCenter}

        &:not(.sponsor) {
          svg {
            path {
              fill: ${({ theme }) => theme.iconFill};
            }
          }
        }

        &:active {
          transform: scale(0.86);
        }

        &:hover {
          background: ${({ theme }) => theme.iconHoverBg};

          span {
            opacity: 0.6;
            visibility: visible;
          }
        }

        span {
          opacity: 0;
          visibility: hidden;
          width: fit-content;
          position: absolute;
          word-break: keep-all;
          white-space: nowrap;
          padding: 4px 6px;
          font-size: 13px;
          font-weight: 400;
          color: var(--cr-white);
          border-radius: 3px;
          background: var(--cr-black);
          bottom: -26px;
          transition: var(--ease-1);
        }
      }

      .lang-switch {
        margin: 0 8px;
      }

      .day-night {
        div {
          opacity: 0;
          position: absolute;
          transition: var(--ease-1);
          ${flexCenter}
        }
      }
    }

    &-middle {
      ${flexCenter}
      flex-direction: column;
      position: relative;
      top: -40px;

      h1 {
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 67px;
        letter-spacing: -0.01em;
        color: ${({ theme }) => theme.title};
        margin-bottom: 8px;
        margin-top: 20px;
        text-align: center;
        transition: var(--ease-1);
        white-space: nowrap;
      }

      p {
        max-width: 270px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: ${({ theme }) => theme.text};
        text-align: center;
        transition: var(--ease-1);
      }

      .button-group {
        display: flex;
        margin-top: 48px;

        .btn-mac-active {
          svg {
            transform: rotate(-180deg);
          }
        }

        .mac-download-list-show {
          opacity: 1 !important;
          visibility: visible !important;
        }

        .mac-download-list {
          z-index: 1;
          opacity: 0;
          visibility: hidden;
          position: absolute;
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 6px 0px;
          box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12),
            0px 11px 16px rgba(0, 0, 0, 0.06);
          bottom: -80px;
          background: var(--cr-white);
          color: var(--cr-font-2);
          border-radius: 10px;

          a {
            width: 100%;
            padding: 8px 12px;
            color: var(--cr-font-2);
            cursor: pointer;
            white-space: nowrap;

            &:hover {
              background: var(--color-iceBlue-2);
              color: var(--cr-white);
            }
          }
        }

        .btn-download {
          padding: 14px 24px;
          border-radius: 10px;
          color: white;
          background: ${({ theme }) => theme.primaryButton};
          transition: var(--ease-1);
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1),
            0px 2px 8px 0px rgba(0, 0, 0, 0.085);
          white-space: nowrap;
          ${flexCenter}

          &:active {
            transform: scale(0.9);
          }

          img {
            margin-right: 10px;
          }
        }

        .btn-mac {
          position: relative;
          margin-right: 12px;
          color: ${({ theme }) => theme.primaryButtonText};

          i {
            ${flexCenter}
            transition: var(--ease-1);
          }

          svg {
            margin-left: 10px;
            transition: var(--ease-1);
          }
        }

        .btn-win {
          background: var(--color-iceBlue-2);

          &:hover {
            background: var(--color-iceBlue-1);
          }
        }
      }

      .app-info {
        display: flex;
        margin-top: 18px;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        color: ${({ theme }) => theme.text};

        span {
          ${flexCenter}
          margin-right: 14px;
          transition: var(--ease-1);

          div {
            ${flexCenter}
            transition: var(--ease-1);
          }

          svg {
            margin-right: 4px;
            opacity: 0.6;
          }
        }

        .mac-info {
          cursor: pointer;
          position: relative;
          transition: var(--ease-1);
          white-space: nowrap;
          ${flexCenter}

          &:hover {
            opacity: 1;
            &:after {
              opacity: 0.8;
              transform: scale(1);
            }
          }

          &:after {
            opacity: 0;
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            padding: 3px 6px;
            background: ${({ theme }) => theme.iconBg};
            border-radius: 4px;
            transform: scale(0.96);
            transition: var(--ease-1);
          }
        }
      }
    }

    & > div {
      width: 100%;
    }

    footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 12px;
      color: ${({ theme }) => theme.footerText};

      .footer-left {
        position: relative;
        display: flex;
        align-items: center;
        transition: var(--ease-1);
        white-space: nowrap;

        i {
          height: 16px;
          width: 1px;
          background: ${({ theme }) => theme.line};
          margin: 0 12px;
        }

        &-logo {
          position: relative;
          width: 128px;
          height: 32px;
          transition: var(--ease-1);

          &:hover {
            opacity: 0.8;
          }

          &-night {
            opacity: 0;
          }
          img {
            position: absolute;
            width: 128px;
            height: 32px;
            transition: var(--ease-1);
          }
        }
      }

      .footer-right {
        display: flex;

        .link-wechat {
          position: relative;

          &:hover {
            &:after {
              opacity: 1;
              visibility: visible;
              transform: perspective(500px) rotateX(0);
            }
          }

          &:after {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            left: -48px;
            top: -132px;
            content: "";
            display: block;
            padding: 8px;
            width: 100px;
            height: 100px;
            background: url(${wechat_qr}) no-repeat 50%;
            background-size: cover;
            border-radius: 4px;
            transition: all 0.2s ease-out;
            transform-origin: bottom;
            transform: perspective(500px) rotateX(10deg) scale(0.96);
            /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.6); */
            /* filter: saturate(0) invert(1) contrast(0.8); */
          }
        }

        a {
          display: block;
          width: 18px;
          height: 18px;
          margin-right: 12px;
          transition: var(--ease-1);

          &:last-child {
            margin-right: 0px;
          }

          &:hover {
            /* filter: contrast(10); */
          }

          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
`;

export default App;
