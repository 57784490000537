export const ButtonDropArrow = () => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        d="M1 1L5 5L9 1"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const IconSun = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M9 2.10867C9.57428 2.10867 10.0499 1.6331 10.0499 1.04985C10.0499 0.475573 9.57428 0 9 0C8.42572 0 7.95015 0.475573 7.95015 1.04985C7.95015 1.6331 8.42572 2.10867 9 2.10867ZM13.8814 4.12762C14.2851 4.53141 14.9581 4.53141 15.3619 4.12762C15.7836 3.71486 15.7836 3.05085 15.3619 2.63809C14.9581 2.22532 14.2851 2.22532 13.8814 2.63809C13.4686 3.05085 13.4686 3.71486 13.8814 4.12762ZM2.62911 4.13659C3.04187 4.54038 3.70588 4.54038 4.10967 4.13659C4.53141 3.71486 4.53141 3.05982 4.10967 2.64706C3.70588 2.2343 3.04187 2.2343 2.62911 2.64706C2.21635 3.05982 2.21635 3.71486 2.62911 4.13659ZM9 13.5942C11.4945 13.5942 13.5763 11.5214 13.5763 9.01795C13.5763 6.52343 11.4945 4.45065 9 4.45065C6.50548 4.45065 4.4327 6.52343 4.4327 9.01795C4.4327 11.5214 6.50548 13.5942 9 13.5942ZM1.04985 10.0678C1.62413 10.0678 2.0997 9.59222 2.0997 9.00897C2.0997 8.4347 1.62413 7.95912 1.04985 7.95912C0.475573 7.95912 0 8.4347 0 9.00897C0 9.59222 0.475573 10.0678 1.04985 10.0678ZM16.9501 10.1306C17.5244 10.1306 18 9.65504 18 9.07178C18 8.49751 17.5244 8.02193 16.9501 8.02193C16.3759 8.02193 15.9003 8.49751 15.9003 9.07178C15.9003 9.65504 16.3759 10.1306 16.9501 10.1306ZM2.63809 15.3709C3.05085 15.7836 3.71486 15.7836 4.11864 15.3709C4.53141 14.9581 4.53141 14.2941 4.11864 13.8814C3.71486 13.4776 3.05085 13.4776 2.63809 13.8814C2.21635 14.2941 2.21635 14.9581 2.63809 15.3709ZM13.8275 15.4158C14.2403 15.8285 14.9043 15.8285 15.317 15.4158C15.7298 15.012 15.7298 14.348 15.317 13.9352C14.9043 13.5224 14.2403 13.5224 13.8275 13.9352C13.4148 14.348 13.4148 15.012 13.8275 15.4158ZM9 18C9.57428 18 10.0499 17.5334 10.0499 16.9501C10.0499 16.3759 9.57428 15.9003 9 15.9003C8.42572 15.9003 7.95015 16.3759 7.95015 16.9501C7.95015 17.5334 8.42572 18 9 18Z"
        fill="black"
      />
    </svg>
  );
};

export const IconMoon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M12.4758 10.8065C8.1129 10.8065 5.32258 8.07258 5.32258 3.70161C5.32258 2.79839 5.54032 1.50806 5.83065 0.83871C5.90323 0.653226 5.91936 0.540323 5.91936 0.459677C5.91936 0.241935 5.75807 0 5.44355 0C5.35484 0 5.16129 0.0241935 4.98387 0.0887097C2 1.28226 0 4.49194 0 7.87097C0 12.6129 3.6129 16 8.33871 16C11.8145 16 14.8226 13.8952 15.8226 11.2661C15.8952 11.0806 15.9113 10.8871 15.9113 10.8145C15.9113 10.5161 15.6613 10.3145 15.4355 10.3145C15.3306 10.3145 15.2419 10.3387 15.0887 10.3871C14.4677 10.5887 13.4677 10.8065 12.4758 10.8065Z"
        fill="black"
      />
    </svg>
  );
};

export const IconLove = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.65359 16C8.8366 16 9.09804 15.878 9.28976 15.7647C14.1961 12.6275 17.3072 8.97604 17.3072 5.26362C17.3072 2.17865 15.1895 0 12.4532 0C10.7538 0 9.44662 0.941176 8.65359 2.37909C7.878 0.949891 6.55338 0 4.85403 0C2.11765 0 0 2.17865 0 5.26362C0 8.97604 3.11111 12.6275 8.02614 15.7647C8.20915 15.878 8.47059 16 8.65359 16Z"
        fill="#E05A32"
      />
    </svg>
  );
};

export const IconUILink = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4117 7C11.0867 7 10.8234 7.26328 10.8234 7.58828L10.8234 10.8235H3.17648V3.17656H6.41169C6.73669 3.17656 6.99997 2.91328 6.99997 2.58828C6.99997 2.26328 6.73669 2 6.41169 2H2.58828C2.26328 2 2 2.26367 2 2.58828V11.4117C2 11.7367 2.26367 12 2.58828 12H11.4117C11.7367 12 12 11.7363 12 11.4117V7.58828C12 7.26328 11.7363 7 11.4117 7ZM8.17614 2.58831C8.17614 2.91331 8.43942 3.17659 8.76442 3.17659L8.7648 3.17707H9.99181L6.584 6.58447C6.35431 6.81416 6.35431 7.18642 6.584 7.41611C6.81369 7.6458 7.18595 7.6458 7.41564 7.41611L10.8234 4.00831V5.23561C10.8234 5.56061 11.0871 5.82389 11.4117 5.82389C11.7367 5.82389 12 5.56022 12 5.23561V2.58831C12 2.2637 11.7363 2.00003 11.4117 2.00003H8.76442C8.43942 2.00003 8.17614 2.2637 8.17614 2.58831Z"
        fill="white"
      />
    </svg>
  );
};

export const IconLang = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M8.991 0C4.023 0 0 4.032 0 9C0 13.968 4.023 18 8.991 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 8.991 0ZM15.228 5.4H12.573C12.2913 4.28531 11.8742 3.20931 11.331 2.196C12.9727 2.7612 14.3561 3.89855 15.228 5.4ZM9 1.836C9.747 2.916 10.332 4.113 10.719 5.4H7.281C7.668 4.113 8.253 2.916 9 1.836ZM2.034 10.8C1.89 10.224 1.8 9.621 1.8 9C1.8 8.379 1.89 7.776 2.034 7.2H5.076C5.004 7.794 4.95 8.388 4.95 9C4.95 9.612 5.004 10.206 5.076 10.8H2.034ZM2.772 12.6H5.427C5.715 13.725 6.129 14.805 6.669 15.804C5.02557 15.2419 3.64125 14.1037 2.772 12.6ZM5.427 5.4H2.772C3.64125 3.89626 5.02557 2.75811 6.669 2.196C6.12578 3.20931 5.70868 4.28531 5.427 5.4ZM9 16.164C8.253 15.084 7.668 13.887 7.281 12.6H10.719C10.332 13.887 9.747 15.084 9 16.164ZM11.106 10.8H6.894C6.813 10.206 6.75 9.612 6.75 9C6.75 8.388 6.813 7.785 6.894 7.2H11.106C11.187 7.785 11.25 8.388 11.25 9C11.25 9.612 11.187 10.206 11.106 10.8ZM11.331 15.804C11.871 14.805 12.285 13.725 12.573 12.6H15.228C14.3561 14.1014 12.9727 15.2388 11.331 15.804ZM12.924 10.8C12.996 10.206 13.05 9.612 13.05 9C13.05 8.388 12.996 7.794 12.924 7.2H15.966C16.11 7.776 16.2 8.379 16.2 9C16.2 9.621 16.11 10.224 15.966 10.8H12.924Z"
        fill="black"
      />
    </svg>
  );
};

export const IconInfo = () => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7C0 3.412 2.912 0.5 6.5 0.5C10.088 0.5 13 3.412 13 7C13 10.588 10.088 13.5 6.5 13.5C2.912 13.5 0 10.588 0 7ZM7.15003 5.05H5.85003V3.75H7.15003V5.05ZM7.15003 10.2499H5.85003V6.34989H7.15003V10.2499Z"
        fill="black"
      />
    </svg>
  );
};

export const IconClose = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M1.29298 14.5625C0.894547 14.961 0.882828 15.6406 1.29298 16.0508C1.69142 16.461 2.38283 16.4492 2.78127 16.0508L8.78127 10.0391L14.793 16.0508C15.1914 16.4492 15.8711 16.461 16.2695 16.0508C16.6914 15.6406 16.6797 14.961 16.2695 14.5625L10.2695 8.5508L16.2695 2.5508C16.6797 2.15236 16.6914 1.46095 16.2695 1.06252C15.8711 0.65236 15.1914 0.664078 14.793 1.06252L8.78127 7.07423L2.78127 1.06252C2.38283 0.664078 1.69142 0.65236 1.29298 1.06252C0.882828 1.46095 0.894547 2.15236 1.29298 2.5508L7.3047 8.5508L1.29298 14.5625Z"
        fill="black"
      />
    </svg>
  );
};
