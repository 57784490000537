import { useState, useEffect } from "react";
import gsap from "gsap";
import { Draggable } from "gsap/all";
import styled from "styled-components";
import sidebarFigma from "../assets/UI/sidebar-figma.png";
import sidebarCn from "../assets/UI/sidebar-cn.png";
import sidebarDock from "../assets/UI/sidebar-dock.png";
import sidebarDark from "../assets/UI/sidebar-dark.png";
import coverFigma from "../assets/UI/app-cover-figma.png";
import coverCn from "../assets/UI/app-cover-cn.png";
import coverDock from "../assets/UI/app-cover-dock.png";
import coverDark from "../assets/UI/app-cover-dark.png";
import { IconUILink } from "../svg";

gsap.registerPlugin(Draggable);

const sidebarIcons = [sidebarFigma, sidebarCn, sidebarDock, sidebarDark];
const covers = [coverFigma, coverCn, coverDock, coverDark];

function AppUI(props) {
  const { dark, copywrite } = props;
  const [sidebarActive, setSidebarActive] = useState(1);
  const [sidebarIndex, setSidebarIndex] = useState(1);

  useEffect(() => {
    Draggable.create(".hero-left-app", {
      type: "x,y",
      edgeResistance: 0.65,
      bounds: ".hero-left",
      inertia: true,
    });
  }, []);

  function clickHandle(i) {
    setSidebarIndex(i);
    setSidebarActive(i);
  }

  return (
    <AppUICss className="hero-left-app">
      <div className="hero-left-app-sidebar">
        <ul className="window-controls">
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <ul className="sidebar-iterm-wrap">
          {copywrite.ui.sidebar.map((item, i) => {
            return (
              <li
                className={sidebarActive === i ? "item-active" : ""}
                key={i}
                onClick={() => clickHandle(i)}
              >
                <img src={sidebarIcons[i]} alt="sidebarIcons" />
                {item}
              </li>
            );
          })}
        </ul>
        <div className="hero-left-app-sidebar-footer">
          {copywrite.ui.footer.map((item, i) => {
            return <p key={i}>{item}</p>;
          })}
        </div>
      </div>
      <div className="hero-left-app-content">
        {
          <>
            <h3>{copywrite.ui.content[sidebarIndex].title}</h3>
            <p>{copywrite.ui.content[sidebarIndex].des}</p>
            <div className="buttons">
              <div>{copywrite.ui.content[sidebarIndex].buttons[0]}</div>
              <div>
                {copywrite.ui.content[sidebarIndex].buttons[1]}
                <IconUILink />
              </div>
            </div>
            <img
              className="content-cover"
              src={covers[sidebarIndex]}
              alt="cover"
            />
          </>
        }
      </div>
    </AppUICss>
  );
}

const AppUICss = styled.div`
  opacity: 0;
  perspective: 1200px;
  z-index: 1;
  position: absolute;
  width: 640px;
  height: 400px;
  border-radius: 6px;
  background: ${({ theme }) => theme.appBg};
  backdrop-filter: blur(50px);
  display: flex;
  overflow: hidden;
  box-shadow: 0px 25px 30px rgba(0, 0, 0, 0.35),
    0px 0px 20px rgba(0, 0, 0, 0.15);
  margin-top: -50px;
  border: ${({ theme }) => theme.appBorder};

  .item-active {
    background: ${({ theme }) => theme.appItemBg};
  }

  .hero-left-app-sidebar {
    width: 190px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-footer {
      position: absolute;
      bottom: 26px;
      left: 26px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: ${({ theme }) => theme.footerText};

      p:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }

  .window-controls {
    display: flex;
    position: absolute;
    left: 12px;
    top: 12px;
    li {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      border: 0.5px solid rgba(0, 0, 0, 0.12);
      margin-right: 8px;

      &:nth-of-type(1) {
        background: #ff5e57;
      }
      &:nth-of-type(2) {
        background: #ffbb2e;
      }
      &:nth-of-type(3) {
        background: #e3e3e3;
      }
    }
  }

  .sidebar-iterm-wrap {
    width: 154px;
    margin-top: 40px;

    li {
      cursor: default;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 4px 8px;
      color: ${({ theme }) => theme.appItemText};
      margin-bottom: 6px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      border-radius: 4px;
      transition: var(--ease-1);

      &:active {
        transform: scale(0.9);
      }

      &:hover {
        background: ${({ theme }) => theme.appItemBg};
      }

      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }
  }

  .hero-left-app-content {
    position: relative;
    background: ${({ theme }) => theme.appContentBg};
    width: 450px;
    height: 400px;
    padding: 30px 50px 0 50px;

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: ${({ theme }) => theme.appItemText};
      margin-bottom: 6px;
      white-space: nowrap;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      color: ${({ theme }) => theme.appItemText};
    }

    .buttons {
      margin-top: 24px;
      display: flex;

      div {
        width: 130px;
        height: 36px;
        border-radius: 6px;
        background: #5555f6;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-right: 20px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;

        svg {
          margin-left: 4px;
        }

        &:nth-of-type(2) {
          background: #2c2c34;
        }
      }
    }

    .content-cover {
      position: absolute;
      width: 400px;
      height: 234px;
      bottom: 0;
      pointer-events: none;
    }
  }
`;

export default AppUI;
