import { createGlobalStyle } from "styled-components";

const MobileStyles = createGlobalStyle`
@media screen and (max-width: 1330px) {
    footer{
      flex-direction: column;

      .footer-left {
          order: 1;
          margin-top: 12px;
      }
    }
}
@media screen and (max-width: 1100px) {
    .App {
        flex-direction: column;
        height: unset !important;
        padding: 10px 10px 20px 10px !important;
    }

    .hero-right {
        opacity: 1  !important;
    }

    h1 {
        font-size: 36px !important;
        line-height: 44px !important;
        margin-top: 8px !important;
    }

    .hero-left-app {
        margin-top: -40px;
    }

    .button-group {
        margin-top: 32px !important;
    }

    .info-modal {
        width: 90vw !important;
        height: -webkit-fill-available !important;
        height: fit-content !important;
        p {
           max-width: unset !important;     
        }
    }

    .hero-left {
        position: relative !important;
        width: 100% !important;
        height: 46vh !important;
    }
    .hero-right {
        position: relative !important;
        width: 100% !important;
        height: 660px !important;
        padding: 20px 20px 0 20px !important;

        &-top {
            position: absolute !important;;
            right: 0 !important;
        }

       &-middle {
           top: 40px !important;

          & > div:first-child {
               width: 180px !important;
               height: 180px !important;
           }

           .button-group {
                flex-direction: column !important;

                .btn-win {
                    margin-bottom: 0 !important;
                }

                .btn-download {
                    width: 100% !important;

                    &:nth-of-type(1) {
                        margin-bottom: 8px;
                    }
                }
           }
       }
    }
}
`;

export default MobileStyles;
