export const lightTheme = {
  body: "#FFF",
  title: "rgba(0,0,0,0.9)",
  text: "rgba(0, 0, 0, 0.6)",
  line: "rgba(0,0,0,0.14)",
  footerText: "rgba(0, 0, 0, 0.4)",
  background: "#363537",
  primaryButton: "#333",
  primaryButtonText: "#fff",
  iconBg: "rgba(0,0,0,0.04)",
  iconFill: "#000",
  iconHoverBg: "rgba(0,0,0,0.08)",
  dockBg: "rgba(255,255,255,0.3)",
  dockTipBg: "#9a91bb",
  dockBorder: "rgba(255, 255, 255, 0.15)",
  dockText: "rgba(0, 0, 0, 0.8)",
  appBg: "rgba(255, 255, 255, 1)",
  appItemBg: "#eff3f6",
  appItemText: "rgba(0, 0, 0, 0.8)",
  appContentBg: "#eff3f6",
  appBorder: "0px solid transparent",
  alertBg: "rgba(238, 242, 255)",
  alertText: "rgb(67, 56, 202)",

};
export const darkTheme = {
  body: "#222426",
  title: "rgba(255,255,255,0.9)",
  text: "rgba(255, 255, 255, 0.6)",
  line: "rgba(255,255,255,0.14)",
  footerText: "rgba(255, 255, 255, 0.4)",
  background: "#999",
  primaryButton: "#fff",
  primaryButtonText: "#333",
  iconBg: "rgba(255,255,255,0.1)",
  iconFill: "#fff",
  iconHoverBg: "rgba(255,255,255,0.08)",
  dockBg: "rgba(16,16,16,0.3)",
  dockTipBg: "rgba(16,16,16,0.3)",
  dockBorder: "rgba(0, 0, 0, 0.15)",
  dockText: "rgba(255, 255, 255, 0.8)",
  appBg: "rgba(40, 40, 40, 0.4)",
  appItemBg: "rgba(44, 44, 52, 0.4)",
  appItemText: "rgba(255, 255, 255, 0.8)",
  appContentBg: "#222426",
  appBorder: "1px solid rgba(255, 255, 255, 0.12)",
  alertBg: "rgb(41 57 109)",
  alertText: "rgb(196 191 255)",
};
