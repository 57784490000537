export const copywriteCn = {
  fixedAlert: {
    title: "重要信息：关于 Figma Box 无法正常使用的问题。",
    button: "查看详情",
    des: `Figma 官方近日禁止了第三方修改客户端文件，所以导致安装 Figma Box 后无法正常打开 Figma。
    <br/>
    <br/>
    目前部分用户是旧版本客户端，还能正常使用，建议暂时不要更新 Figma。我们在寻找解决方案的同时，也会将 Figma Box 中的工具制作成浏览器插件供大家使用，请持续关注。
    <br/>
    <br/>
    <a href="https://gitee.com/kogoro/figma-download-mirror" target="_blank">旧版 Figma 下载</a>    
    `,
  },
  hero: {
    title: "Figma Box 工具箱",
    slogan: "我们为 Figma 制作实用、快捷的拓展工具，让设计更得心应手",
    button: {
      mac: "macOS 版本",
      win: "Windows 版本",
    },
    dropdown: {
      mac: "下载 macOS 版本",
      intel: "下载 Intel 版本",
      m1: "下载 M1 版本",
      win: "下载 Windows 版本",
      spon: "赞助我们",
      link: "Figma.Cool",
    },
  },
  ui: {
    sidebar: ["官方客户端", "界面汉化", "插件快捷栏", "深色模式"],
    content: [
      {
        title: "Figma —— 基于网络可协作的设计软件",
        des: "从头到尾进行创造、测试、发布和交付设计",
        buttons: ["客户端下载", "官方网站"],
      },
      {
        title: "Figma 界面汉化工具，设计师人工校验翻译",
        des: "支持客户端和浏览器页面汉化",
        buttons: ["客户端汉化", "浏览器汉化"],
      },
      {
        title: "Figma 插件快捷栏",
        des: "提供插件的 Dock 和列表，帮助你快捷使用插件",
        buttons: ["客户端安装", "浏览器安装"],
      },
      {
        title: "Figma 深色模式",
        des: "提供深色配色界面，设计更有沉浸感",
        buttons: ["客户端安装", "浏览器安装"],
      },
    ],
    footer: ["使用说明", "设置", "更新日志", "Figma.Cool"],
  },
  footer: "Copyright © 2021",
  info: {
    version: "v1.0.0",
    title: "macOS 安装提示",
    content:
      "如遇 「Figma Box 已损坏，无法打开。 您应该将它移到废纸篓」的系统报错，打开 「终端」输入 sudo xattr -rd com.apple.quarantine /Applications/Figma\\ Box.app 然后输入开机密码回车。",
  },
  alert: {
    title: "⚠️ 警告",
    content: `下面是运行 Box 之前的提示和操作。
    <br/>
    <br/>
    - 重新安装最新版本 <a href="https://www.figma.com/downloads">Figma 客户端</a><br/>
    - 如果遇到 Installation has failed？请参考 <a href="https://zhuanlan.zhihu.com/p/370574072">https://zhuanlan.zhihu.com/p/370574072</a><br/>
    - 启用 Box 里面的功能之后，重启 Figma<br/>
    - 如果遇到不可恢复的情况，请直接重装 Figma，卸载 Figma Box
    `,
  },
  feedback: "问题反馈",
  tooltips: {
    spon: "赞助我们",
    lang: "中英切换",
    dark: "Light/Dark",
  },
};

export const copywriteEn = {
  fixedAlert: {
    title: "重要信息：关于 Figma Box 无法正常使用的问题。",
    button: "查看详情",
    des: `Figma 官方近日禁止了第三方修改客户端文件，所以导致安装 Figma Box 后无法正常打开 Figma。
    <br/>
    <br/>
    目前部分用户是旧版本客户端，还能正常使用，建议暂时不要更新 Figma。我们在寻找解决方案的同时，也会将 Figma Box 中的工具制作成浏览器插件供大家使用，请持续关注。`,
  },
  hero: {
    title: "Figma Toolbox",
    slogan:
      "We create practical and efficient extension tools for Figma to make design more convenient",
    button: {
      mac: "macOS",
      win: "Windows",
    },
    dropdown: {
      mac: "Download macOS Version",
      intel: "Intel Version",
      m1: "M1 Version",
      win: "Download Windows Version",
      spon: "Sponsor us",
      link: "Figma.Cool",
    },
  },
  ui: {
    sidebar: [
      "Official Client",
      "Chineseization",
      "Plugin Quick Dock",
      "Dark Mode",
    ],
    content: [
      {
        title: "Figma - web-based collaborable design software",
        des: "Create, test, release and deliver designs from start to finish",
        buttons: ["Client Download", "Official Website"],
      },
      {
        title: "Figma interface Chineseization tool",
        des: "Support for client and browser page Chineseization",
        buttons: ["Client Chineseization", "Browser Chinese"],
      },
      {
        title: "Figma Plugin Quick Dock",
        des: "Provides a dock and list of plugins to help you use them quickly",
        buttons: ["Client Install", "Browser Install"],
      },
      {
        title: "Figma Dark Mode",
        des: "Provides a darker color scheme interface with a more immersive design",
        buttons: ["Client Install", "Browser Install"],
      },
    ],
    footer: ["Info", "Settings", "Update Log", "Figma.Cool"],
  },
  footer: "Copyright © 2021",
  info: {
    version: "v1.0.1.beta",
    title: "macOS Install Tips",
    content: `If you get the "Figma Box is corrupted and cannot be opened. You should move it to the wastebasket", open Terminal and type sudo xattr -rd com.apple.quarantine /Applications/Figma\ Box.app and enter the boot password.`,
  },
  alert: {
    title: "⚠️ Warning",
    content: `Figma Box beta currently has some unknown issues, so please install the beta version of your choice. Here are the tips and actions to take before running Box.
    <br/>
    <br/>
    - Reinstall the latest version of the <a href="https://www.figma.com/downloads">Figma Client</a><br/>
    - If you encounter Installation has failed, please refer to <a href="https://zhuanlan.zhihu.com/p/370574072">https://zhuanlan.zhihu.com/p/370574072</a><br/>
    - After enabling the functions in Box, restart Figma<br/>
    - If you encounter a non-recoverable situation, please just reinstall Figma and uninstall Figma Box
    `,
  },
  feedback: "Feedback",
  tooltips: {
    spon: "Sponsor us",
    lang: "Language",
    dark: "Light/Dark",
  },
};
