import reset from "react-style-reset/string";
import { createGlobalStyle } from "styled-components";
import cursor from "../assets/cursor.svg";

const GlobalStyles = createGlobalStyle`
  ${reset};

  .fixed-alert-modal {
    height: 300px !important;

    a {
      color: #0e00ff
    }
  }

  .alert {
    max-width: fit-content;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 20px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content:center;
    --tw-bg-opacity: 1;
    background-color:${({ theme }) => theme.alertBg};
    padding: 12px 20px;
    border-radius: 8px;
    line-height: 1.45rem;
    --tw-text-opacity: 1;
    color: ${({ theme }) => theme.alertText};
    transition: all 0.6s ease;
    transform: translateY(-200px);

    & > div {
      display: flex;
    }

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    button {
      box-shadow: var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow);
      --tw-text-opacity: 1;
      font-size: .875rem;
    line-height: 1.25rem;
    padding-left: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-right: 1rem;
    font-weight: 500;
    color: rgba(255,255,255,var(--tw-text-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(79,70,229,var(--tw-bg-opacity));
    border-radius: .375rem;
    border-width: 0;
    margin-left: 16px;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
    }
  }

  .alertShow {
    transform: translateY(0)
  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
  }

  html, body {
    scroll-behavior: smooth;
  }
  
  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background:${({ theme }) => theme.body};
    overflow-x: hidden;
    overflow-y: scroll;
    cursor: url(${cursor}) 6 2, default;
    transition: var(--ease-1);
  }

  body::-webkit-scrollbar {
  display: none;
}

  :root {
      --cr-white: #fff;
      --cr-black: #000;
      --cr-bg-1: #333333;
      --cr-bg-2: #181818;
      --cr-bg-3: rgba(23, 23, 23, 0.06);
      --cr-bg-4: #000;
      --cr-bg-5:rgba(0, 0, 0, 0.04);
      --cr-bg-6:rgba(0, 0, 0, 0.08);
      --cr-line-1: rgba(255, 255, 255, 0.12);
      --cr-line-2: rgba(255, 255, 255, 0.4);
      --cr-line-3: rgba(0, 0, 0, 0.14);
      --ease-1: all 0.25s ease;
      --ease-2: all 0.55s cubic-bezier(0.34, 1.56, 0.64, 1);
      --ease-3: all 0.45s ease;
      --ease-4: all 0.3s ease-out;
      --color-red-1: #f65c8a;
      --color-purple-1: #5f5cf6;
      --color-iceBlue-1: #00b5ff;
      --color-iceBlue-2: #0099FF;
      --color-yellow-1: #eba800;
      --cr-font-1: rgba(255, 255, 255, 0.9);
      --cr-font-2: rgba(0, 0, 0, 0.8);
      --cr-font-3: rgba(255, 255, 255, 0.7);
  }

  .react-contexify {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 11px 16px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
  }

  .react-contexify__separator {
    background-color: var(--cr-line-3);
  }

  .react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content, .react-contexify__item:not(.react-contexify__item--disabled):focus > .react-contexify__item__content{
      &:hover {
        background-color: var(--color-iceBlue-2);

        a {
        color: white;
        }
      }
  }

  .react-contexify__item__content {
      font-size: 14px;
      color: var(--cr-font-2);

      a {
          width: 100%;
          height: 100%;
        color: var(--cr-font-2);
      }
  }

`;

export default GlobalStyles;
