import styled from "styled-components";
import logoFigmaBox from "../assets/figmaBox-icon.png";
import logoFigma from "../assets/figma-icon.png";
import logoFigmaBeta from "../assets/figmaBeta-icon.png";

function Dock() {
  return (
    <DockCss className="dock">
      <div className="dock-item dock-item-1">
        <img src={logoFigmaBox} alt="logoFigmaBox" className="figmabox-icon" />
        <i>Figma Box</i>
      </div>
      <div className="dock-item">
        <img src={logoFigma} alt="logoFigma" />
        <i>Figma</i>
      </div>
      <div className="dock-item">
        <img src={logoFigmaBeta} alt="logoFigmaBeta" />
        <i>Figma Beta</i>
      </div>
    </DockCss>
  );
}

const DockCss = styled.div`
  opacity: 0;
  z-index: 1;
  position: absolute;
  bottom: 20px;
  /* width: 300px;
  height: 103px; */
  padding: 6px 12px;
  background: ${({ theme }) => theme.dockBg};
  border: 1px solid ${({ theme }) => theme.dockBorder};
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: var(--ease-1);

  .dock-item {
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;

    i {
      opacity: 0;
      display: flex;
      justify-content: center;
      position: absolute;
      top: -60px;
      padding: 8px 13px;
      background: ${({ theme }) => theme.dockTipBg};
      border: 1px solid ${({ theme }) => theme.dockBorder};
      box-shadow: inset 0px 0px 6.66667px rgb(0 0 0 / 10%);
      border-radius: 8px;
      color: ${({ theme }) => theme.dockText};
      white-space: nowrap;

      &:before {
        position: absolute;
        content: "";
        width: 12px;
        height: 12px;
        background: ${({ theme }) => theme.dockTipBg};
        border-radius: 2px;
        border-bottom: 1px solid ${({ theme }) => theme.dockBorder};
        color: white;
        bottom: -21%;
        transform: rotate(45deg);
      }
    }
  }

  div {
    &:first-child {
      &:after {
        content: "";
        z-index: 1;
        position: absolute;
        bottom: 1px;
        width: 4px;
        height: 4px;
        border: 1px solid #3c3d3f;
        border-radius: 10px;
        background: #3c3d3f;
      }
    }
    &:nth-of-type(1) {
      img {
        position: relative;
        top: -10px;
        transform: scale(1.2);
      }
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      img {
        transition: var(--ease-1);
      }
    }
  }

  img {
    width: 80px;
    height: 80px;

    &:hover {
      transform: scale(1.2) translateY(-10px);

      & ~ i {
        opacity: 1;
      }
    }
  }
`;

export default Dock;
